<template>
  <div class="fluid" id="configList">
    <div>
      <div class="config-list-div">
        <v-card elevation="0" class="px-3 py-2" style="width: 100%">
          <v-row>
            <v-col
              cols="12"
              class="d-flex"
              style="
                position: relative;
                padding: 0 20px;
                text-align: center;
                margin-top: 20px;
              "
            >
              <h4 class="indigo--text" style="font-weight: bold; margin: auto">
                KONFIGURASI
                <v-tooltip bottom color="grey">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      x-small
                      text
                      icon
                      color="grey darken-2"
                      @click="getDataFromApi"
                    >
                      <v-icon small>mdi-refresh</v-icon>
                    </v-btn>
                  </template>
                  <span>refresh page</span>
                </v-tooltip>
              </h4>
            </v-col>
            <v-col cols="12">
              <div>
                <v-toolbar
                  class="config-toolbar-1"
                  flat
                  style="
                    height: 60px;
                    border-radius: 10px 10px 0 0;
                    padding-top: 15px;
                  "
                >
                  <div class="d-flex align-content-start">
                    <div
                      style="margin-right: 15px; height: 45px"
                      class="d-flex justify-center align-center"
                    >
                      <v-btn
                        style="font-size: 12px"
                        @click="addConfig"
                        elevation="2"
                        tilerounded="5px"
                        color="primary"
                        class="white--text"
                        small
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" v-on="on" small class="">
                              mdi-plus
                            </v-icon>
                          </template>
                          <span>Tambah Konfigurasi</span>
                        </v-tooltip>
                      </v-btn>
                    </div>
                  </div>
                  <div style="width: 210px; height: 45px">
                    <v-autocomplete
                      v-model="paramAPI.company_id"
                      label="Perusa"
                      item-text="name"
                      item-value="id"
                      return-id
                      dense
                      outlined
                      clearable
                      @change="companyWatcher"
                      :items="dropdown.company"
                      :disabled="isDisableCompanyDropdown"
                    ></v-autocomplete>
                  </div>
                  <div style="width: 210px; height: 45px; margin-left: 10px">
                    <v-autocomplete
                      v-model="paramAPI.category_id"
                      label="Kategori"
                      item-text="name"
                      item-value="id"
                      return-id
                      dense
                      outlined
                      clearable
                      @change="categoryWatcher"
                      :items="dropdown.category"
                    ></v-autocomplete>
                  </div>
                  <div style="width: 160px; height: 45px; margin-left: 10px">
                    <v-autocomplete
                      v-model="paramAPI.template"
                      label="Template"
                      item-text="name"
                      item-value="id"
                      return-id
                      dense
                      outlined
                      @change="templateWatcher"
                      :items="dropdown.template"
                    ></v-autocomplete>
                  </div>
                  <div style="width: 330px; height: 45px; margin-left: auto">
                    <v-text-field
                      v-model="paramAPI.keyword"
                      @keyup.enter="searchEnter"
                      label="Search here"
                      type="search"
                      append-icon="mdi-magnify"
                      outlined
                      dense
                    ></v-text-field>
                  </div>
                </v-toolbar>
              </div>
            </v-col>

            <v-col cols="12" style="width: 100%">
              <v-data-table
                v-if="result.length > 0 || !skeleton_loading"
                mobile-breakpoint="0"
                fixed-header
                height="60vh"
                :headers="headers"
                style="cursor: pointer"
                :items="result"
                :loading="loading"
                @click:row="rowClick"
                item-key="id"
                :options.sync="options"
                :footer-props="{
                  showFirstLastPage: true,
                  showCurrentPage: true,
                  itemsPerPageOptions: [1, 3, 5, 10, 15, 100]
                }"
                :server-items-length="totalData"
                :page="paramAPI.page"
                @update:page="updatePage"
                :items-per-page="paramAPI.itemsPerPage"
                @update:items-per-page="updateItemPerPage"
              >
                <template v-slot:[`item.no`]="{ index }">
                  <div
                    style="
                      font-size: 14px;
                      padding: 0;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    {{ index + 1 + paramAPI.offset * paramAPI.limit }}
                  </div>
                </template>
                <template v-slot:[`item.name`]="{ item }">
                  <div
                    style="
                      font-size: 12px;
                      padding: 0;
                      width: 150px;
                      display: flex;
                      flex-direction: row;
                      color: green;
                    "
                  >
                    {{ item.name }}
                  </div>
                </template>
                <template v-slot:[`item.code`]="{ item }">
                  <div
                    style="
                      font-size: 12px;
                      padding: 0;
                      width: 150px;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    {{ item.code }}
                  </div>
                </template>
                <template v-slot:[`item.value`]="{ item }">
                  <div
                    style="
                      font-size: 12px;
                      padding: 0;
                      width: 70px;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    {{ item.value }}
                  </div>
                </template>
                <template v-slot:[`item.company_name`]="{ item }">
                  <div
                    style="
                      font-size: 12px;
                      width: 200px;
                      padding: 0;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    {{ item.company_name }}
                  </div>
                </template>
                <template v-slot:[`item.start_salary`]="{ item }">
                  <div style="padding: 0; display: flex; flex-direction: row">
                    {{ priceConvert(item.start_salary) }}
                  </div>
                </template>
                <template v-slot:[`item.registered_salary`]="{ item }">
                  <div
                    style="
                      width: 100px;
                      padding: 0;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    {{ priceConvert(item.registered_salary) }}
                  </div>
                </template>
                <template v-slot:[`item.end_salary`]="{ item }">
                  <div style="padding: 0; display: flex; flex-direction: row">
                    {{ priceConvert(item.end_salary) }}
                  </div>
                </template>
                <template
                  v-slot:[`item.percentage_registered_salary`]="{ item }"
                >
                  <div
                    style="
                      padding: 0;
                      width: 90px;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    {{
                      item.percentage_registered_salary !== null
                        ? item.percentage_registered_salary
                        : '-'
                    }}
                  </div>
                </template>
                <template
                  v-slot:[`item.calculation_registered_salary_method`]="{
                    item
                  }"
                >
                  <div
                    style="
                      width: 140px;
                      padding: 0;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    {{
                      getCalculationMethodName(
                        item.calculation_registered_salary_method
                      )
                    }}
                  </div>
                </template>
                <template v-slot:[`item.createdAt`]="{ item }">
                  <div
                    style="
                      font-size: 12px;
                      width: 80px;
                      padding: 0;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    {{ convertDate(item.createdAt) }}
                  </div>
                </template>
                <template v-slot:[`item.updatedAt`]="{ item }">
                  <div
                    style="
                      font-size: 12px;
                      width: 80px;
                      padding: 0;
                      display: flex;
                      flex-direction: row;
                    "
                  >
                    {{ convertDate(item.updatedAt) }}
                  </div>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }"
                      ><v-icon
                        v-bind="attrs"
                        v-on="on"
                        @click.stop="rowClick(item)"
                        small
                        color="primary"
                        class="mr-2"
                      >
                        mdi-pencil
                      </v-icon>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                  <v-tooltip
                    v-if="
                      getUserProfile.level.find(({ id }) => id === '1') !==
                      undefined
                    "
                    right
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        small
                        color="error"
                        @click.stop="deleteItem(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                </template>
              </v-data-table>
              <v-skeleton-loader
                v-else
                style="width: 100%"
                type="table-heading, table-tbody, table-tfoot"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </div>
    <v-dialog v-model="configDialog" persistent max-width="500px">
      <v-card v-if="form !== null">
        <v-card-title>
          <span class="text-h5">Add/Edit Konfigurasi</span>
        </v-card-title>
        <v-form ref="entryForm2" @submit.prevent="saveConfig">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" class="d-flex flex-column">
                  <p
                    class="text-left"
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Perusahaan
                  </p>
                  <v-autocomplete
                    v-model="form.company"
                    :items="dropdown.company"
                    :disabled="isDisableCompanyDropdown"
                    :rules="rules.companyRules"
                    item-text="name"
                    item-value="id"
                    return-object
                    dense
                    outlined
                    clearable
                    style="height: 45px"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="d-flex flex-column">
                  <p
                    class="text-left"
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Kategori
                  </p>
                  <v-autocomplete
                    v-model="form.category_id"
                    :items="dropdown.category"
                    :rules="rules.categoryRules"
                    item-text="name"
                    item-value="id"
                    return-id
                    dense
                    outlined
                    clearable
                    style="height: 45px"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" class="d-flex flex-column">
                  <p
                    class="text-left"
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Nama
                  </p>
                  <v-text-field
                    v-model="form.name"
                    :rules="rules.nameRules"
                    dense
                    outlined
                    style="height: 45px"
                  />
                </v-col>
                <v-col cols="6" class="d-flex flex-column">
                  <p
                    class="text-left"
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Kode
                  </p>
                  <v-text-field
                    v-model="form.code"
                    :rules="rules.codeRules"
                    dense
                    outlined
                    style="height: 45px"
                  />
                </v-col>
                <v-col cols="6" class="d-flex flex-column">
                  <p
                    class="text-left"
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Template
                  </p>
                  <v-autocomplete
                    v-model="form.template"
                    :items="dropdown.template"
                    :rules="rules.templateRules"
                    item-text="name"
                    item-value="id"
                    return-id
                    dense
                    outlined
                    clearable
                    style="height: 45px"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6" class="d-flex flex-column">
                  <p
                    class="text-left"
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Tipe Nilai
                  </p>
                  <v-select
                    v-model="form.value_type"
                    :items="['', 'text', 'number']"
                    item-text="name"
                    item-value="id"
                    return-object
                    outlined
                    dense
                    clearable
                    style="height: 45px"
                  />
                </v-col>
                <v-col cols="6" class="d-flex flex-column">
                  <p
                    class="text-left"
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Nilai
                  </p>
                  <v-text-field
                    v-model="form.value"
                    :rules="rules.valueRules"
                    dense
                    outlined
                    style="height: 45px"
                    :type="form.value_type"
                    :disabled="form.value_type === ''"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeConfigDialog">
              Close
            </v-btn>
            <v-btn color="blue darken-1" text type="submit"> Save </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from 'axios'
import { mapGetters, mapMutations /*  mapActions */ } from 'vuex'
import buildType from '../../../services/buildType'
export default {
  name: 'configuration',
  data: () => ({
    editDialog: false,
    configDialog: false,
    eSanqua: buildType.apiURL('esanqua'),
    hrsApi: buildType.apiURL('hrs'),
    build: process.env.VUE_APP_BUILD_TYPE,
    paramAPI: {
      page: 1,
      keyword: '',
      offset: 0,
      limit: 10,
      itemsPerPage: 10,
      sortBy: 'date',
      sortType: 'desc',
      company_id: null,
      category_id: null,
      template: ''
      // department_id: null
    },
    totalData: 0,
    form: null,
    detail: null,
    isEdit: false,

    options: {},
    actionValue: 0,
    headers: [
      {
        text: 'No',
        value: 'no',
        align: 'left',
        sortable: false
      },
      {
        text: 'Nama',
        value: 'name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Kategori',
        value: 'category_id',
        align: 'left',
        sortable: false
      },
      {
        text: 'Kode',
        value: 'code',
        align: 'left',
        sortable: false
      },
      {
        text: 'Nilai',
        value: 'value',
        align: 'left',
        sortable: false
      },
      {
        text: 'Template',
        value: 'template',
        align: 'left',
        sortable: false
      },
      {
        text: 'Perusahaan',
        value: 'company_name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Dibuat pada',
        value: 'createdAt',
        align: 'left',
        sortable: false
      },
      {
        text: 'Diedit pada',
        value: 'updatedAt',
        align: 'left',
        sortable: false
      },
      {
        text: 'Actions',
        value: 'action',
        align: 'left',
        sortable: false
      }
    ],
    result: [],
    loading: false,
    isDisableCompanyDropdown: false,
    rules: {
      companyRules: [],
      categoryRules: [],
      templateRules: [],
      nameRules: [],
      codeRules: [],
      valueRules: []
    },
    dropdown: {
      company: [],
      category: [
        {
          id: 1,
          name: 'Employee (1)'
        },
        {
          id: 2,
          name: 'Leave (2)'
        },
        {
          id: 3,
          name: 'Permission (3)'
        },
        {
          id: 4,
          name: 'Payroll BPJS (4)'
        },
        {
          id: 5,
          name: 'Payroll Calculate Late (5)'
        },
        {
          id: 6,
          name: 'Pembagi Hari (6)'
        },
        {
          id: 7,
          name: 'Payroll (7)'
        },
        {
          id: 8,
          name: 'UMR (8)'
        },
        {
          id: 9,
          name: 'TAX (9)'
        },
        {
          id: 10,
          name: 'Attendance (10)'
        }
      ],
      template: [
        {
          name: 'Default',
          id: 'default'
        },
        {
          name: 'Template 1',
          id: 'template_1'
        },
        {
          name: 'Template 2',
          id: 'template_2'
        },
        {
          name: 'Template 3',
          id: 'template_3'
        },
        {
          name: 'Template 4',
          id: 'template_4'
        },
        {
          name: 'Template 5',
          id: 'template_5'
        }
      ]
    },
    skeleton_loading: true
  }),

  async mounted() {
    this.paramAPI.company_id = Number(
      this.getUserProfile.employee.company.plant_id
    )
    this.paramAPI.department_id = this.getUserProfile.employee.department_id
    if (this.getPayrollConfigPageQuery !== null) {
      this.paramAPI = this.getPayrollConfigPageQuery
    }
    setTimeout(async () => {
      await this.getDataFromApi()
      await this.initDropdown()
    }, 200)
  },
  computed: {
    // , 'getDropdownPlant'
    ...mapGetters(['getUserProfile', 'getPayrollConfigPageQuery'])
  },
  watch: {},
  methods: {
    // ...mapActions(['dropdownPlant']),
    ...mapMutations(['setPayrollConfigPageQuery']),
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    async initDropdown() {
      this.dropdownCompany()
    },
    resetSomeParam() {
      this.paramAPI.limit = this.paramAPI.itemsPerPage
      this.paramAPI.offset = 0
      this.paramAPI.page = 1
    },
    searchEnter() {
      this.resetSomeParam()
      this.getDataFromApi()
    },
    companyWatcher() {
      this.resetSomeParam()
      this.getDataFromApi()
    },
    categoryWatcher() {
      this.resetSomeParam()
      this.getDataFromApi()
    },
    templateWatcher() {
      this.resetSomeParam()
      this.getDataFromApi()
    },

    async dropdownCompany() {
      const level = this.getUserProfile.level.find(
        ({ id }) => id === '1' || id === '39'
      )
      let xFlag = false
      if (level !== undefined) {
        if (level.user_user_level !== null) {
          if (
            level.user_user_level.allowed_company === null ||
            level.user_user_level.allowed_company.length === 0
          ) {
            xFlag = true
          } else {
            this.dropdown.company = level.user_user_level.allowed_company
            this.isDisableCompanyDropdown = false
          }
        } else {
          xFlag = true
        }
      } else {
        xFlag = true
      }

      if (xFlag) {
        if (Number(this.getUserProfile.employee.company.plant_id) !== 6) {
          this.isDisableCompanyDropdown = true
        } else {
          this.isDisableCompanyDropdown = false
        }
        axios
          .get(`${this.eSanqua}master/universal/plant/dropdown`)
          .then((res) => {
            this.dropdown.company = res.data.data
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },

    async getDataFromApi() {
      this.loading = true
      await this.initDataTable()
        .then((data) => {
          if (data.status_code === '00') {
            this.totalData = data.total_record
            return (this.result = data.data)
          }
          return (this.result = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.result = [])
        })
      this.skeleton_loading = false
      this.loading = false
    },
    async initDataTable() {
      let url = `${this.hrsApi}config/list?keyword=${
        this.paramAPI.keyword
      }&offset=${this.paramAPI.offset * this.paramAPI.limit}&limit=${
        this.paramAPI.limit
      }`
      const filter = []
      if (this.paramAPI.company_id !== null) {
        filter.push(`{"company_id": ${this.paramAPI.company_id}}`)
      }
      if (this.paramAPI.category_id !== null) {
        filter.push(`{"category_id": ${this.paramAPI.category_id}}`)
      }
      if (this.paramAPI.template !== '') {
        filter.push(`{"template": "${this.paramAPI.template}"}`)
      }
      url = url + `&filter=[${filter}]`
      this.setPayrollConfigPageQuery(this.paramAPI)
      return await new Promise((resolve) => {
        axios
          .get(url)
          .then((res) => {
            console.log('config>>>>', res)
            resolve(res.data)
          })
          .catch((err) => {
            console.log(err)
          })
      })
    },
    enableEdit() {
      var oldForm = this.form
      this.isEdit = !this.isEdit
      if (!this.isEdit) {
        this.form = oldForm
        if (this.form.act === 'add') {
          this.closeDialog()
        }
      }
    },

    rowClick(pItem) {
      this.form = {
        act: 'update',
        id: pItem.id,
        company: {
          id: pItem.company_id,
          name: pItem.company_name
        },
        code: pItem.code,
        name: pItem.name,
        category_id: pItem.category_id,
        value: pItem.value,
        template: pItem.template
      }
      setTimeout(() => {
        this.configDialog = !this.configDialog
      }, 300)
    },
    async deleteItem(pItem) {
      this.loading = true
      this.showMsgDialog(
        'question',
        'Are you sure, This data will be deleted permanently',
        true
      ).then(async (res) => {
        if (res.isConfirmed) {
          await axios
            .delete(`${this.hrsApi}config/delete/${pItem.id}`)
            .then(async (res) => {
              if (res.data.status_code !== '-99') {
                this.showMsgDialog('success', res.data.status_msg, false)
                this.getDataFromApi()
              } else {
                this.showMsgDialog('warning', res.data.status_msg, false)
              }
              this.loading = false
            })
            .catch((err) => {
              console.log(err)
              this.loading = false
              this.showMsgDialog(
                'error',
                err
                  ? 'Something went wrong, Please contact an admin!'
                  : 'Something went wrong, Please contact an admin!',
                false
              )
            })
        }
      })
    },
    closeDialog() {
      this.form = null
      this.isEdit = false
      setTimeout(() => {
        this.editDialog = false
      }, 300)
    },
    addConfig() {
      this.form = {
        act: 'add',
        company: null,
        name: '',
        code: '',
        value: '',
        category_id: null,
        template: null,
        value_type: ''
      }
      setTimeout(() => {
        this.configDialog = !this.configDialog
      }, 300)
    },
    saveConfig() {
      this.rules.companyRules = [(v) => !!v || 'Company is required']
      this.rules.nameRules = [(v) => !!v || 'Name is required']
      this.rules.categoryRules = [(v) => !!v || 'Category is required']
      // this.rules.codeRules = [(v) => !!v || 'Code is required']
      this.rules.valueRules = [(v) => !!v || 'Value is required']
      // this.rules.templateRules = [(v) => !!v || 'Template is required']
      const self = this
      setTimeout(function () {
        if (self.$refs.entryForm2.validate()) {
          console.log(self.form)
          const form = {
            act: self.form.act,
            name: self.form.name,
            company_id: self.form.company.id,
            company_name: self.form.company.name,
            value: self.form.value,
            category_id: Number(self.form.category_id)
          }
          if (self.form.code !== null) {
            Object.assign(form, {
              code: self.form.code
            })
          }
          if (self.form.template !== null) {
            Object.assign(form, {
              template: self.form.template
            })
          }
          if (form.act === 'update') {
            Object.assign(form, { id: self.form.id })
          }
          console.log(form)
          self.submitConfig(form)
        }
      })
    },
    async submitConfig(form) {
      await axios
        .post(`${this.hrsApi}config/save`, form)
        .then(async (res) => {
          console.log(res.data)
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            this.getDataFromApi()
            this.closeConfigDialog()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'warning',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('warning', res.data.status_msg, false)
            }
          }
        })
        .catch((err) => {
          console.log(err)
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
        })
    },
    closeConfigDialog() {
      this.form = null
      setTimeout(() => {
        this.configDialog = false
      }, 300)
    },
    async updatePage(p) {
      this.paramAPI.page = p
      this.paramAPI.offset = p - 1
      await this.getDataFromApi()
    },
    async updateItemPerPage(p) {
      this.paramAPI.itemsPerPage = p
      this.paramAPI.limit = p
      this.paramAPI.offset = 0
      await this.getDataFromApi()
    },
    convertDate(raw) {
      if (raw !== null) {
        const date = new Date(raw)
        const local = date.toLocaleString('id')
        return local
      } else {
        return '-'
      }
    }
  }
}
</script>
<style lang="scss">
#configList {
  position: relative;
  .config-list-div {
    padding: 15px;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .config-toolbar-1 {
      .config-toolbar-1 {
        width: 60%;
        display: flex;
        flex-direction: row;
        margin-left: auto;
      }
    }
    .activity-list-toolbar-2 {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  #configList {
    .config-list-div {
      .config-toolbar-1 {
        .config-toolbar-1 {
          display: none;
        }
      }
      .activity-list-toolbar-2 {
        display: block;
      }
    }
  }
}
</style>
